<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Bank</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Bank"
              >
                <b-form-input
                  v-model="bankname"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Bank"
                  rules="required"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Branch"
              >
                <b-form-input
                  v-model="bankbranch"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Branch"
                  rules="required"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Account No</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Account No"
              >
                <b-form-input
                  v-model="bankaccno"
                  type="number"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Account No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>IFSC Code</label>
              <b-form-input
                v-model="ifsccode"
                maxlength="11"
                @input="validateIFSCCodeClick($event)"
                placeholder="Enter IFSC Code"
              />
              <small v-if="ifsccodeValidate == false" class="text-danger"
                >Enter Valid IFSC Code</small
              >
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
              :disabled="!flag"
              @click.prevent="submitForm"
            >
              Save changes
            </b-button>
            <b-button
              v-if="back"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click="handleBack()"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import useAppConfig from "@core/app-config/useAppConfig";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormFile,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import BCardCode from "@core/components/b-card-code";
import Attachment from "../../../components/Attechment.vue";
import axios from '@/components/axios';
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    Attachment,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BImgLazy,
    BFormFile,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
    back: {
      default: true,
    },
    pending: {
      default: false,
    },
  },
  data() {
    return {
      bankname:"",
      bankbranch:"",
      bankaccno:"",
      ifsccode:"",
      flag: true,
      ifsccodeValidate: true,
      userData: JSON.parse(localStorage.getItem("userData")),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
    };
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    async getUserData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${this.userData.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }).then((response) => {
        this.getEditValue(response.data.data[0]);
      });
    },
    validateIFSCCodeClick(e) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      this.ifsccode = this.ifsccode.toUpperCase();
      if (regex.test(this.ifsccode) == true) {
        this.ifsccodeValidate = true;
      } else {
        this.ifsccodeValidate = false;
      }
    },
    getEditValue(item) {
      this.bankname = item.bankname
      this.bankbranch = item.bankbranch
      this.bankaccno = item.bankaccno
      this.ifsccode = item.ifsccode
    },
    submitForm(e) {
    let data={
      bankname :this.bankname,
      bankbranch :this.bankbranch,
      bankaccno :this.bankaccno,
      ifsccode :this.ifsccode,
    }
      // var ifEdit = this.$route.query.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (
          success == false ||
          this.ifsccodeValidate == false
        ) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success && this.ifsccodeValidate == true) {
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: "PUT",
            url: `${baseApi}/auth/userupdate/${this.userData.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.$store.dispatch('app/getUserData')
              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$forceUpdate();
            })
            .catch((error) => {
              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleBack() {
      this.$router.push("/");
    },
  },
};
</script>
