<template>
  <b-card-code>
    <b-row>
      <b-col md="9" v-if="userData.role == 'channelpartner'">
        <ecommerce-statistics :data="statisticsItems" :cp="true" />
      </b-col>
    </b-row>
    </b-card-code>
</template>
<script>
import EcommerceStatistics from "../../dashboard/ecommerce/EcommerceStatistics.vue";
import { BRow, BCol, BModal, BTabs, BTab,BButton } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import axios from '@/components/axios';

export default{
  components:{
    EcommerceStatistics,BRow, BCol, BModal, BTabs, BTab,BButton,BCardCode
  },
  data(){
    return{
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      statisticsItems: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: "0",
          subtitle: "Sales",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "rupee",
          color: "light-success",
          title: "0",
          subtitle: "Commission",
          customClass: "",
        },
      ],
      userData: "",

    }
  },
  mounted(){
    this.userData = JSON.parse(localStorage.getItem("userData"));
    if (this.userData.role == "channelpartner") {
        this.getDashboardWidgets();
      }
  },
  methods:{
    async getDashboardWidgets() {
      console.log('first')
      await axios({
        method: "GET",
        url: `${this.baseApi}/dashboardwidgets`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }).then((response) => {
        this.statisticsItems[0].title = response.data.data.sales;
        this.statisticsItems[1].title = response.data.data.commission;

      });
    },
  }
}
</script>
