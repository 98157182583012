<template>
  <b-tabs content-class="col-12 col-md-9 mt-1 mt-md-0" pills vertical nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left">

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">KYC</span>
      </template>

      <profile-kyc/>
    </b-tab>

    <b-tab>

    <template #title>
      <feather-icon icon="BoxIcon" size="18" class="mr-50" />
      <span class="font-weight-bold">Bank Details</span>
    </template>

    <bank-details/>
    </b-tab>

    <b-tab>

<template #title v-if="userData.role == 'channelpartner'">
  <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          fill="currentColor"
          class="bi bi-currency-rupee"
          viewBox="0 0 16 16"
        >
          <path
            d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z"
          />
        </svg>
  <span class="font-weight-bold">Earning Details</span>
</template>
<earning-details/>
</b-tab>
    <!-- social links -->
    <!-- <b-tab> -->

    <!-- title -->
    <!-- <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Social</span>
      </template>

      <account-setting-social
        v-if="options.social"
        :social-data="options.social"
      />
    </b-tab> -->

    <!-- notification -->
    <!-- <b-tab> -->

    <!-- title -->
    <!-- <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import ProfileKyc from './ProfileKyc.vue'
import BankDetails from './BankDetails.vue'
import EarningDetails from './EarningDetails.vue'
// import AccountSettingInformation from './AccountSettingInformation.vue'
// import AccountSettingSocial from './AccountSettingSocial.vue'
// import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    BankDetails,
    AccountSettingGeneral,
    AccountSettingPassword,
    ProfileKyc,
    EarningDetails
    // AccountSettingInformation,
    // AccountSettingSocial,
    // AccountSettingNotification,
  },
  data () {
    return {
      options: {},
      userData:{}
    }
  },
  mounted(){
this.userData=JSON.parse(localStorage.getItem("userData"))
}
}
</script>
